import { combineReducers } from "redux";
import sidebar from "./modules/Sidebar/reducer";
import auth from "./modules/User/reducer";
import alert from "./modules/Alert/reducer";

export default combineReducers({
  sidebar,
  auth,
  alert,
});
